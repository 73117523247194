/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import { validEmail } from 'src/utils/form';
import Layout from '../components/layout';
import '../styles/Pages/forgot_password/styles.scss';
import Spinner from '../images/spinner.svg';
import { SERVER_URL } from '../utils/environment';
import { FormattedMessage } from 'react-intl';
import { getLocale } from '../utils/locale';
import LanguageSelector from '../components/LanguageSelector';
class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      sent: false,
      retry: false,
      second: 0,
      validated: false,
      error: '',
    };

    this.timer = null;
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  setTimer = () => {
    const { second } = this.state;

    if (second > 0) {
      this.setState({ second: second - 1 });
      this.timer = setTimeout(() => this.setTimer(), 1000);
    }
  };

  handleChange = event => {
    const { value } = event.target;

    if (value) {
      if (validEmail.test(value)) {
        this.setState({ validated: true, error: '' });
      } else {
        this.setState({
          validated: false, error: <FormattedMessage
            id="error.emailMustBeValid"
            defaultMessage="Email must be valid"
          />
        });
      }
    }

    this.setState({ email: value, sent: false });
  };

  forgotPassword = () => {
    this.setState({ loading: true, sent: false });
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords`, {
        email: this.state.email,
      })
      .then(() => {
        this.setState(
          {
            loading: false,
            sent: true,
            second: 60,
            retry: false,
          },
          () => this.setTimer(),
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = event => {
    const { second, validated } = this.state;
    event.preventDefault();

    if (!validated) return;

    if (second === 0) {
      this.forgotPassword();
    } else {
      this.setState({ loading: false, retry: true });
    }
  };

  render() {
    const {
      loading,
      email,
      sent,
      second,
      retry,
      validated,
      error,
    } = this.state;
    return (
      <Layout location={this.props.location}>
        <div className="forgot-password" role="main">
          <div className="password-container">
            <LanguageSelector style={{ textAlign: 'right', transform: 'translateY(-25px)' }} />
            <h1>
              <FormattedMessage
                id="auth.forgotMyPassword"
                defaultMessage="Forgot Password"
              />
            </h1>
            <p>
              <FormattedMessage
                id="auth.alreadyHaveYourPassword"
                defaultMessage="Already have your password?"
              />
              {getLocale() === 'fr' ? <br /> : ' '}
              <b>
                <Link
                  style={{ color: '#2e5fca' }}
                  title="Sign In"
                  to="/?signin=true"
                >
                  <FormattedMessage
                    id="auth.signIn"
                    defaultMessage="Sign In"
                  />
                </Link>
              </b>
            </p>
            <form onSubmit={this.handleSubmit}>
              <label htmlFor="email">
                <FormattedMessage
                  id="auth.email"
                  defaultMessage="Email"
                />
              </label>
              <input
                id="email"
                name="email"
                type="text"
                onChange={this.handleChange}
                disabled={loading || second > 0}
              />
              <p role="alert" className="error-message">
                {error}
              </p>
              <button
                className={`fp-button ${(loading || second > 0 || !validated) &&
                  'fp-button-disable'} ${getLocale() === 'fr' && 'lg'}`}
                type="submit"
                aria-disabled={(loading || second > 0 || !validated)}
              >
                <FormattedMessage
                  id="auth.sendPasswordReset"
                  defaultMessage="SEND PASSWORD RESET"
                />
              </button>
            </form>
            {loading && (
              <p>
                <span className="spinner">
                  <img src={Spinner} alt="loading" />
                </span>
                <FormattedMessage
                  id="auth.sending"
                  defaultMessage="Sending..."
                />
              </p>
            )}
            {sent && !(second > 0 && retry) && (
              <p role="alert">
                <FormattedMessage
                  id="auth.ifAccountExists"
                  values={{ email: <b>{email}</b> }}
                />
              </p>
            )}
            {second > 0 && retry && (
              <p role="alert" aria-live="polite">
                <FormattedMessage
                  id="auth.alreadyRequestedPasswordReset"
                  values={{ second: <b>{second}</b> }}
                />
              </p>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

ForgotPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ForgotPassword;
